export const kids = [
  { image: "/images/kids/1.jpg" },
  { image: "/images/kids/2.jpg" },
  { image: "/images/kids/3.jpg" },
  { image: "/images/kids/4.jpg" },
  { image: "/images/kids/5.jpg" },
  { image: "/images/kids/6.jpg" },
  { image: "/images/kids/7.jpg" },
  { image: "/images/kids/8.jpg" },
  { image: "/images/kids/9.jpg" },
  { image: "/images/kids/10.jpg" },
  { image: "/images/kids/11.jpg" },
  { image: "/images/kids/12.jpg" },
  { image: "/images/kids/13.jpg" },
  { image: "/images/kids/14.jpg" },
  { image: "/images/kids/15.jpg" },
  { image: "/images/kids/16.jpg" },
  { image: "/images/kids/17.jpg" },
  { image: "/images/kids/18.jpg" },
  { image: "/images/kids/19.jpg" },
  { image: "/images/kids/20.jpg" },
  { image: "/images/kids/21.jpg" },
  { image: "/images/kids/22.jpg" },
  { image: "/images/kids/23.jpg" },
  { image: "/images/kids/24.jpg" },
  { image: "/images/kids/25.jpg" },
  { image: "/images/kids/26.jpg" },
  { image: "/images/kids/27.jpg" },
  { image: "/images/kids/28.jpg" },
  { image: "/images/kids/29.jpg" },
  { image: "/images/kids/30.jpg" },
  { image: "/images/kids/31.jpg" },
  { image: "/images/kids/32.jpg" },
  { image: "/images/kids/33.jpg" },
  { image: "/images/kids/34.jpg" },
  { image: "/images/kids/35.jpg" },
  { image: "/images/kids/36.jpg" },
  { image: "/images/kids/37.jpg" },
  { image: "/images/kids/38.jpg" },
  { image: "/images/kids/39.jpg" },
  { image: "/images/kids/40.jpg" },
  { image: "/images/kids/41.jpg" },
  { image: "/images/kids/42.jpg" },
  { image: "/images/kids/43.jpg" },
  { image: "/images/kids/44.jpg" },
  { image: "/images/kids/45.jpg" },
  { image: "/images/kids/46.jpg" },
  { image: "/images/kids/47.jpg" },
  { image: "/images/kids/48.jpg" },
  { image: "/images/kids/49.jpg" },
  { image: "/images/kids/50.jpg" },
  { image: "/images/kids/51.jpg" },
  { image: "/images/kids/52.jpg" },
  { image: "/images/kids/53.jpg" },
  { image: "/images/kids/54.jpg" },
  { image: "/images/kids/55.jpg" },
  { image: "/images/kids/56.jpg" },
  { image: "/images/kids/57.jpg" },
  { image: "/images/kids/58.jpg" },
  { image: "/images/kids/59.jpg" },
  { image: "/images/kids/60.jpg" },
  { image: "/images/kids/61.jpg" },
  { image: "/images/kids/62.jpg" },
  { image: "/images/kids/63.jpg" },
  { image: "/images/kids/64.jpg" },
  { image: "/images/kids/65.jpg" },
  { image: "/images/kids/66.jpg" },
  { image: "/images/kids/67.jpg" },
  { image: "/images/kids/68.jpg" },
  { image: "/images/kids/69.jpg" },
  { image: "/images/kids/70.jpg" },
];
