export const womens = [
  { image: "/images/womens/1.jpg" },
  { image: "/images/womens/2.jpg" },
  { image: "/images/womens/3.jpg" },
  { image: "/images/womens/4.jpg" },
  { image: "/images/womens/5.jpg" },
  { image: "/images/womens/6.jpg" },
  { image: "/images/womens/7.jpg" },
  { image: "/images/womens/8.jpg" },
  { image: "/images/womens/9.jpg" },
  { image: "/images/womens/10.jpg" },
  { image: "/images/womens/11.jpg" },
  { image: "/images/womens/12.jpg" },
  { image: "/images/womens/13.jpg" },
  { image: "/images/womens/14.jpg" },
  { image: "/images/womens/15.jpg" },
  { image: "/images/womens/16.jpg" },
  { image: "/images/womens/17.jpg" },
  { image: "/images/womens/18.jpg" },
  { image: "/images/womens/19.jpg" },
  { image: "/images/womens/20.jpg" },
  { image: "/images/womens/21.jpg" },
  { image: "/images/womens/22.jpg" },
  { image: "/images/womens/23.jpg" },
  { image: "/images/womens/24.jpg" },
  { image: "/images/womens/25.jpg" },
  { image: "/images/womens/26.jpg" },
  { image: "/images/womens/27.jpg" },
  { image: "/images/womens/28.jpg" },
  { image: "/images/womens/29.jpg" },
  { image: "/images/womens/30.jpg" },
  { image: "/images/womens/31.jpg" },
  { image: "/images/womens/32.jpg" },
  { image: "/images/womens/33.jpg" },
  { image: "/images/womens/34.jpg" },
  { image: "/images/womens/35.jpg" },
  { image: "/images/womens/36.jpg" },
  { image: "/images/womens/37.jpg" },
  { image: "/images/womens/38.jpg" },
  { image: "/images/womens/39.jpg" },
  { image: "/images/womens/40.jpg" },
  { image: "/images/womens/41.jpg" },
  { image: "/images/womens/42.jpg" },
  { image: "/images/womens/43.jpg" },
  { image: "/images/womens/44.jpg" },
  { image: "/images/womens/45.jpg" },
  { image: "/images/womens/46.jpg" },
  { image: "/images/womens/47.jpg" },
  { image: "/images/womens/48.jpg" },
  { image: "/images/womens/49.jpg" },
  { image: "/images/womens/50.jpg" },
  { image: "/images/womens/51.jpg" },
  { image: "/images/womens/52.jpg" },
  { image: "/images/womens/53.jpg" },
  { image: "/images/womens/54.jpg" },
  { image: "/images/womens/55.jpg" },
  { image: "/images/womens/56.jpg" },
  { image: "/images/womens/57.jpg" },
  { image: "/images/womens/58.jpg" },
  { image: "/images/womens/59.jpg" },
  { image: "/images/womens/60.jpg" },
  { image: "/images/womens/61.jpg" },
  { image: "/images/womens/62.jpg" },
  { image: "/images/womens/63.jpg" },
  { image: "/images/womens/64.jpg" },
  { image: "/images/womens/65.jpg" },
  { image: "/images/womens/66.jpg" },
  { image: "/images/womens/67.jpg" },
  { image: "/images/womens/68.jpg" },
  { image: "/images/womens/69.jpg" },
  { image: "/images/womens/70.jpg" },
];
