export const mens = [
  { image: "/images/mens/1.jpg" },
  { image: "/images/mens/2.jpg" },
  { image: "/images/mens/3.jpg" },
  { image: "/images/mens/4.jpg" },
  { image: "/images/mens/5.jpg" },
  { image: "/images/mens/6.jpg" },
  { image: "/images/mens/7.jpg" },
  { image: "/images/mens/8.jpg" },
  { image: "/images/mens/9.jpg" },
  { image: "/images/mens/10.jpg" },
  { image: "/images/mens/11.jpg" },
  { image: "/images/mens/12.jpg" },
  { image: "/images/mens/13.jpg" },
  { image: "/images/mens/14.jpg" },
  { image: "/images/mens/15.jpg" },
  { image: "/images/mens/16.jpg" },
  { image: "/images/mens/17.jpg" },
  { image: "/images/mens/18.jpg" },
  { image: "/images/mens/19.jpg" },
  { image: "/images/mens/20.jpg" },
  { image: "/images/mens/21.jpg" },
  { image: "/images/mens/22.jpg" },
  { image: "/images/mens/23.jpg" },
  { image: "/images/mens/24.jpg" },
  { image: "/images/mens/25.jpg" },
  { image: "/images/mens/26.jpg" },
  { image: "/images/mens/27.jpg" },
  { image: "/images/mens/28.jpg" },
  { image: "/images/mens/29.jpg" },
  { image: "/images/mens/30.jpg" },
  { image: "/images/mens/31.png" },
  { image: "/images/mens/32.png" },
  { image: "/images/mens/33.png" },
  { image: "/images/mens/34.png" },
  { image: "/images/mens/35.png" },
  { image: "/images/mens/36.png" },
  { image: "/images/mens/37.png" },
  { image: "/images/mens/38.png" },
  { image: "/images/mens/39.png" },
  { image: "/images/mens/40.jpg" },
  { image: "/images/mens/41.jpg" },
  { image: "/images/mens/42.jpg" },
  { image: "/images/mens/43.jpg" },
  { image: "/images/mens/44.jpg" },
  { image: "/images/mens/45.jpg" },
  { image: "/images/mens/46.jpg" },
  { image: "/images/mens/47.jpg" },
  { image: "/images/mens/48.jpg" },
  { image: "/images/mens/49.jpg" },
  { image: "/images/mens/50.jpg" },
  { image: "/images/mens/51.jpg" },
  { image: "/images/mens/52.jpg" },
  { image: "/images/mens/53.jpg" },
  { image: "/images/mens/54.jpg" },
  { image: "/images/mens/55.jpg" },
  { image: "/images/mens/56.jpg" },
  { image: "/images/mens/57.jpg" },
  { image: "/images/mens/58.jpg" },
  { image: "/images/mens/59.jpg" },
  { image: "/images/mens/60.jpg" },
  { image: "/images/mens/61.jpg" },
  { image: "/images/mens/62.jpg" },
  { image: "/images/mens/63.jpg" },
  { image: "/images/mens/64.jpg" },
  { image: "/images/mens/65.jpg" },
  { image: "/images/mens/66.jpg" },
  { image: "/images/mens/67.jpg" },
  { image: "/images/mens/68.jpg" },
  { image: "/images/mens/69.jpg" },
  { image: "/images/mens/70.png" },
];
