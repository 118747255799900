import React from "react";
import "../Pages/Pages.css";
import { Container, Row } from "react-bootstrap";

import complianceImg from "../assets/images/compliance.png";

import accord from "../assets/images/certificatelogos/accord-logo.png";
import bsci from "../assets/images/certificatelogos/bsci-logo.png";
import cit from "../assets/images/certificatelogos/cit-logo.png";
import ctpat from "../assets/images/certificatelogos/ctpat.jpg";
import gots from "../assets/images/certificatelogos/gots-log.png";
import ocs from "../assets/images/certificatelogos/ocs-logo.jpg";
import rcs from "../assets/images/certificatelogos/rcs-logo.jpg";
import sedex from "../assets/images/certificatelogos/sedex-logo.png";
import sgs from "../assets/images/certificatelogos/sgs-logo.png";
import wrap from "../assets/images/certificatelogos/wrap-logo.png";

export default function Compliance() {
  return (
    <div>
      <div className="compliance_bg">
        <h3>COMPLIANCE &amp; ETHICS</h3>
      </div>
      <div className="compliance_content">
        <Container>
          <Row>
            <div className="col-md-6">
              <div className="compliance_image_wrapper">
                <img className="img-fluid" src={complianceImg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <p>
                CB Fashion Asia Ltd. is one of the leading multinational
                readymade apparel sourcing and manufacturing companies in
                Bangladesh with a reputation for quality, reliability, and
                unparalleled customer service. By owning vertically-integrated
                world-class sourcing and developing expertise in every step of
                the process we uncover efficiencies and resource savings most
                cannot see. We leverage our large-scale and global reach to
                create positive impacts on how apparel is made by investing in
                technology, continuous improvements, and sustainable solutions.
              </p>
              <p>
                <strong>Social Compliance:</strong> This is an important area of
                the business of our organization.
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right-square-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
                To uphold the reputation of our Customers, Business Partners
                &amp; our organization by ensuring ethics, integrity and
                technical expertise are uncompromisingly practiced in our audit
                processes.
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right-square-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
                To enlist the factory our compliance team first visits the
                factory for the social compliance audit, if the audit pass then
                we go for order placement. In this regard, we follow the buyers’
                Code of conduct and Local Law.
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right-square-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
                We are monitoring compliance issues with all our enlisted
                suppliers on regular basis announced and unannounced.
              </p>
              <p>
                It has gained tremendous growth for its performance, capacity,
                and commitment toward customers. We provide low-cost sourcing in
                apparel, home textiles, and other goods for companies in the
                fashion industry. Our first purpose is to answer the Customers
                demands in terms of product, fittings, and price point always
                maintaining a high- quality level. Professionalism and maximum
                attention to our Customers demands.
              </p>
            </div>
          </Row>
        </Container>
      </div>
      <div className="compliance_certificate_wrapper my-5">
        <Container>
          <Row>
            <div className="col-md-6">
              <div className="comliance_content">
                <h2>ZERO TOLERANCE POLICY</h2>
                <h4>Compliance Issues to be categorized as ZTV</h4>
                <ul>
                  <li>Child labor</li>
                  <li>
                    Forced labor in any form overt or covert &amp; Modern Day
                    Slavery
                  </li>
                  <li>Discrimination in any form</li>
                  <li>Harassment and Abuse</li>
                  <li>
                    Unauthorized subcontracting including Tier 2 operations
                    regardless of brands
                  </li>
                  <li>
                    Failure to provide access to records or workers’ interviews
                    or any mode of denial that will hamper the social compliance
                    audit process
                  </li>
                  <li>
                    Shared building unless approved by Head of Compliance (any
                    other factory owned by a different owner located in the same
                    building) or factory located in a building that has
                    shops/markets
                  </li>
                  <li>Factory building approved for residential purposes</li>
                  <li>
                    Any unethical practice, such as bribery in the form of cash
                    or kind to facilitate any process.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="certificate_wrapper">
                <h5>
                  CB Fashion Asia Ltd. is associated with 25-30 export-oriented
                  as well as social and environmental compliance-certified
                  manufactories. All of these manufactories are certified by
                  global testing approvals &amp; certifications, which are
                  listed below-
                </h5>
                <Row>
                  <div className="col-md-3">
                    <div data-aos="fade-left" className="certificate">
                      <img
                        className="img-fluid"
                        src={accord}
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div data-aos="fade-left" className="certificate">
                      <img className="img-fluid" src={bsci} alt="" srcSet="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div data-aos="fade-right" className="certificate">
                      <img className="img-fluid" src={sedex} alt="" srcSet="" />
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
