import React from "react";
import "../Pages/Pages.css";
import { Accordion, Container, Row } from "react-bootstrap";

import quality from "../assets/images/quality_bg.png";
import qualityManage from "../assets/images/quality_management.png";
import aqlChart from "../assets/images/aql_chart.png";

export default function Quality() {
  return (
    <div>
      <div className="quality_bg">
        <h3>QUALITY ASSURANCE</h3>
      </div>
      <div className="quality_content">
        <Container>
          <Row>
            <div className="col-md-6">
              <p>
                Quality is all the features in the product to meet customer
                requirements. Quality is a multifaceted concept that describes
                how well a service, process, material, or product possesses
                desired intangible or physical attributes. CB is highly
                concerned about the key operations for a well, organized,
                planned QC inspection on every step of the RMG goods production.
              </p>

              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Quality Management:</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        The act of overseeing all activities and tasks needed to
                        maintain a desired level of excellence.
                      </li>
                      <li>
                        This includes creating and implementing quality planning
                        and assurance, as well as quality control and quality
                        improvement.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Quality Management Components:
                  </Accordion.Header>
                  <Accordion.Body>
                    <img
                      className="img-fluid"
                      src={qualityManage}
                      alt="qualityManage"
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Components of CBs quality management are described below:
                  </Accordion.Header>
                  <Accordion.Body>
                    <h3>Quality Planning- Criteria Driven</h3>
                    <ul>
                      <li>
                        It identifies the standards and determines how to
                        satisfy those standards.
                      </li>
                      <li>
                        It lays out the roles and responsibilities, resources,
                        procedures, and processes to be utilized for quality
                        control and quality assurance.
                      </li>
                    </ul>
                    <h3>Quality Assurance- Prevention driven</h3>
                    <ul>
                      <li>
                        It is the review to ensure aligning with the quality
                        standards.
                      </li>
                      <li>Planned and systematic quality activities.</li>
                      <li>
                        Provide the confidence that the standards will be met.
                      </li>
                    </ul>
                    <h3>Quality Control- Inspection driven</h3>
                    <ul>
                      <li>
                        It addresses the assessment conducted during Quality
                        Assurance for corrective actions.
                      </li>
                      <li>
                        Measure specific results to determine that they match
                        the standards.
                      </li>
                      <li>
                        Use of Statistical Process Control (SPC) for monitoring
                        a process to identify special causes of variation and
                        signal the need to take corrective action when
                        appropriate.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Dimensions of Quality:</Accordion.Header>
                  <Accordion.Body>
                    <h6>
                      The quality of a product or service is assessed based on
                      some parameters which are termed as the dimension of
                      quality.
                    </h6>
                    <ol>
                      <li>Performance</li>
                      <li>Durability</li>
                      <li>Aesthetics</li>
                      <li>Features</li>
                      <li>Serviceability</li>
                      <li>Conformance</li>
                      <li>Reliability</li>
                      <li>Perceived quality.</li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Quality Control:</Accordion.Header>
                  <Accordion.Body>
                    <h6>
                      Quality control is a continuous and regular control of the
                      parameters which affect the quality of the final product.
                      Quality assurance is the “process of designing, producing,
                      evaluating, and assessing products to determine that they
                      meet the desired quality level for a company’s target
                      market”. Quality control is generally understood as
                      assessing for quality after products have already been
                      manufactured and sorted into acceptable and unacceptable
                      categories. It is costly for companies that do not take a
                      quality assurance method, but only look at quality in
                      terms of quality control.
                    </h6>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Quality Control in Apparel Industry:
                  </Accordion.Header>
                  <Accordion.Body>
                    <h6>
                      It comprises of planning, raw data compilation, its
                      investigation and implementation. In the process of
                      quality control, the control at various levels of
                      production of garments is monitored by various control
                      forms. This helps in maintaining continuity in the quality
                      control. Further monitoring of the production process will
                      be controlled by documentation. The stage-by-stage
                      documentation helps in not only achieving the expected
                      quality but also in completion of production within the
                      target time.
                    </h6>
                    <h6>
                      Quality control is the day-to-day implementation of
                      quality assurance, and to succeed you have to be very
                      proactive at all levels. Quality controllers need to learn
                      as much as they can about all aspects of the business as
                      they will need to liaise with all departments in the
                      company as well as customers and suppliers.
                    </h6>
                    <h6>
                      Investment in quality control is a form of insurance, but
                      it does not wait for disaster to happen and then
                      compensate; it works to prevent the disaster from
                      happening in the first place. If implemented properly, it
                      is continuously maintaining the servicing, and repairing
                      your supply chain.
                    </h6>
                    <h6>
                      Quality control in the apparel industry can be executed by
                      classifying the entire industry into the following
                      sub-divisions:
                    </h6>
                    <ol>
                      <li>Pre-production stages</li>
                      <li>Production stages</li>
                      <li>Post-production stages.</li>
                    </ol>
                    <h3>1. Quality control in Pre-production:</h3>
                    <h5>a) Fabric quality control through assessing:</h5>
                    <ul>
                      <li>Comfort properties</li>
                      <li>Color fastness properties</li>
                      <li>Durability properties</li>
                      <li>
                        Other technical properties such as GSM, carded/combed
                        yarn, composition, EPI, PPI, etc.
                      </li>
                    </ul>
                    <h5>b) Other trimmings and accessories such as:</h5>
                    <ul>
                      <li>
                        Closure
                        <ul>
                          <li>
                            Zipper, Button, Hooks, Snap fasteners, Drawstrings,
                            etc.
                          </li>
                        </ul>
                      </li>
                      <li>Interlinings/interfacing</li>
                      <li>Sewing threads</li>
                      <li>Elastic waistband</li>
                      <li>
                        Other design elements such as:
                        <ul>
                          <li>Beads, Sequins, Braids, Fringes etc.</li>
                        </ul>
                      </li>
                    </ul>
                    <h3>2. Quality control in Production:</h3>
                    <ul>
                      <li>QC in Spreading</li>
                      <li>QC in cutting</li>
                      <li>QC in assembling parts</li>
                      <li>QC in sewing</li>
                      <li>QC in finishing.</li>
                    </ul>
                    <h3>
                      3. Quality control in Post-Production/Final inspection:
                    </h3>
                    <ul>
                      <li>
                        Technical parameters such as:
                        <ul>
                          <li>Defects in yarn, fabrics.</li>
                          <li>Defects in trims and accessories.</li>
                          <li>Stitching defects.</li>
                          <li>Seam defects etc.</li>
                        </ul>
                      </li>
                      <li>
                        Performances such as:
                        <ul>
                          <li>Overall appearance.</li>
                          <li>Sizing and fit.</li>
                        </ul>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Acceptable Quality Level (AQL)
                  </Accordion.Header>
                  <Accordion.Body>
                    <h6>
                      AQL is an abbreviation for Acceptable Quality Level or
                      Acceptance Quality Level. AQL is one of the most used
                      terms in the apparel industry when it comes to
                      consideration to quality garment inspection. AQL refers to
                      the maximum number of defective items that could be
                      considered to accept during the random sampling of
                      pre-shipment inspection. To reduce risk before accepting a
                      shipment, AQL sampling is a practical and effective
                      approach to undertake quality assurance on an order of
                      produced items. Generally, for clothing items, 1.5%, 2.5%,
                      4.0%, 6.5%, and 10% AQL values are used depending on the
                      type and price of the clothing.
                    </h6>
                    <h6>
                      AQL depends on the percentage of defective or defects per
                      100 units. Percentage defective is used in the apparel
                      industry for simply made products, components, and
                      materials. It is calculated by taking the number of
                      defective products, multiplying by 100, and dividing by
                      the number of units inspected. Defects per 100 units are a
                      more exact way to find acceptable production lots. Defects
                      per 100 units are calculated by taking the total number of
                      defects, multiplying by 100, and dividing by the number of
                      units inspected.
                    </h6>
                    <h6>
                      Before checking goods, always be prepared and have the
                      approved sample, color swatches, specifications, and all
                      relevant information at hand. It makes your job much
                      easier if you are always methodical, and this applies
                      especially to inspection. You must be meticulous but also
                      quick if you have many deliveries to check in a limited
                      amount of time. It is also crucial to remember that
                      large-scale inspections are frequently expensive,
                      unneeded, and time- consuming.
                    </h6>
                    <h6>
                      It is very unlikely that you will have a delivery with no
                      faults, so you accept that a percentage of the production
                      will have some major faults. The factory should always do
                      a 100% inspection of the goods before packing and to what
                      degree can vary from each factory (this is an important
                      point to check when doing a factory assessment).
                    </h6>
                    <h6>
                      Often, it can just consist of trimming cotton ends and a
                      brief glance at the garment. Other factories do a more
                      detailed inspection. As the customer, you should not have
                      to do a 100% inspection of every delivery, as it is very
                      costly and time-consuming, and it is the responsibility of
                      the factory to check that the delivery is acceptable.
                      However, it is essential that you do a check on the goods
                      before accepting them.
                    </h6>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    Acceptable Quality Level (AQL) Inspection Chart which is
                    strictly followed by CB Fashion Ltd:
                  </Accordion.Header>
                  <Accordion.Body>
                    <h6>
                      Acceptable quality level (AQL) sampling plans are an
                      internationally adopted method of determining the level of
                      quality of a delivery by checking a percentage of the
                      total quantity. A sample size of garments to inspect is
                      determined by the quantity in the delivery and then the
                      number of faulty garments you find after the inspection
                      that you consider acceptable. If the number of faulty
                      garments exceeds the acceptable level, then a 100%
                      inspection is required. The following AQL inspection chart
                      is the most commonly used in the apparel industry.
                    </h6>
                    <h4>Table: AQL inspection chart.</h4>
                    <img className="img-fluid" src={aqlChart} alt="" />
                    <h6>
                      The AQL inspection chart was developed by the military
                      during the World War II to check munitions and has now
                      been adapted for deliveries of all types of products, as
                      the level of rejection can be loosened or tightened,
                      depending on the requirements of your business. Here, we
                      have an example of three levels of inspection used in the
                      textile industry, and you can see that the 2.5 level
                      allows less faulty garments than the 4.0 and 6.5 levels.
                      You may decide to use 4.0 AQL for all your deliveries or,
                      for example, use 2.5 AQL for more expensive luxury items.
                    </h6>
                    <h6>
                      It is important that your factories are aware of the level
                      of inspection you will be using when checking deliveries
                      at your warehouse or at their factory. Ideally, the
                      factory should do its AQL inspection on each delivery
                      before it is shipped. It is common today for companies to
                      use independent inspection companies or, if you have a
                      local office, use your own representatives to do the AQL
                      inspection at the factory before goods are shipped. If the
                      delivery fails the inspection, it can then be rectified
                      before shipping. Whichever method or whomever we use for
                      the inspection, our goal is ultimately to work with
                      factories that build in quality checks at every stage of
                      design and production, so that an AQL on their products
                      becomes more routine than essential.
                    </h6>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-6">
              <div className="quality_image_wrapper">
                <img className="img-fluid" src={quality} alt="" />
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
