import React from "react";
import "../Pages/Pages.css";
import { Container, Row } from "react-bootstrap";

export default function Contact() {
  return (
    <div>
      <section className="contact">
        <Container>
          <Row>
            <div className="col-md-6">
              <div className="contact_info">
                <h2>Contact</h2>
                <p>
                  <b>Address:</b> House-38, Road-15, sector-14
                  <br />
                  Uttara Model Town, Dhaka-1230, Bangladesh. <br />
                  <b>Cell:</b> +8801734 626778 <br />
                  <b>Email:</b> rakib@cbfshionasia.com <br />
                  <b>Website:</b> www.cbfashionasia.com
                </p>
                <h4 className="my-3">Contact Person</h4>
                <h3>Mohammad Rakibuzzaman</h3>
                <h6>Managing Director</h6>
                <p>
                  <b>Mobile:</b> +8801734 626778 <br />
                  <b>Email:</b> rakib@cbfshionasia.com
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <h2>Get in touch</h2>
                <form
                  method="POST"
                  action="mailto:rakib@cbfshionasia.com"
                  enctype="multipart/form-data"
                >
                  <div className="mb-3">
                    <label htmlFor="name">Your Name (required)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name*"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Your Email (required)</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email*"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Your Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact"
                      placeholder="Contact*"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject*"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name">Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Your Message"
                      rows="3"
                      required
                    ></textarea>
                  </div>
                  <button className="btn btn-primary" type="submit">
                    SEND MESSAGE
                  </button>
                </form>
              </div>
            </div>
          </Row>
          {/* <Row className="my-5">
            <div className="col-md-12">
              <div className="google_map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3648.8453458753747!2d90.39791911429913!3d23.85962489051333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c5f8f0fda4a5%3A0x9a6df48d94a76440!2sJNC%20Sourcing%20Limited!5e0!3m2!1sen!2sbd!4v1667648353188!5m2!1sen!2sbd"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </Row> */}
        </Container>
      </section>
    </div>
  );
}
