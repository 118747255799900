import React, { useEffect, useState } from "react";
import "./Pages.css";
import AOS from "aos";
import { Container, Row } from "react-bootstrap";
import HeroSlider from "../components/HeroSlider/HeroSlider";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import eddiesBauer from "../assets/images/buyer/eddies-bauer.png";
import emart from "../assets/images/buyer/emart.png";
import forever from "../assets/images/buyer/forever-21.jpg";
import guess from "../assets/images/buyer/guess.png";
import hardRock from "../assets/images/buyer/hard-rock.png";
import head from "../assets/images/buyer/head.png";
import homePlus from "../assets/images/buyer/home-plus.jpg";
import joyRich from "../assets/images/buyer/joy-rich.jpg";
import keithHaring from "../assets/images/buyer/keith-haring.jpg";
import richCotton from "../assets/images/buyer/rich-cotton.jpg";
import skechers from "../assets/images/buyer/skechers.jpg";
import usPolo from "../assets/images/buyer/us-polo.jpg";

import accord from "../assets/images/certificatelogos/accord-logo.png";
import bsci from "../assets/images/certificatelogos/bsci-logo.png";
import cit from "../assets/images/certificatelogos/cit-logo.png";
import ctpat from "../assets/images/certificatelogos/ctpat.jpg";
import gots from "../assets/images/certificatelogos/gots-log.png";
import ocs from "../assets/images/certificatelogos/ocs-logo.jpg";
import rcs from "../assets/images/certificatelogos/rcs-logo.jpg";
import sedex from "../assets/images/certificatelogos/sedex-logo.png";
import sgs from "../assets/images/certificatelogos/sgs-logo.png";
import wrap from "../assets/images/certificatelogos/wrap-logo.png";

import aboutCompany from "../assets/images/about_bg_img.jpg";

export default function HomePage() {
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);
  return (
    <div>
      <HeroSlider />
      <section className="about_company">
        <Container>
          <Row>
            <div className="col-md-4">
              <div data-aos="zoom-in" className="about_wrapper">
                <img className="img-fluid" src={aboutCompany} alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="about_content">
                <h2 data-aos="fade-up">Who we are?</h2>
                <p data-aos="fade-left">
                  <b>CB FASHION ASIA LTD</b> is working as a 100% Exported
                  Oriented Buying Agent in Bangladesh. We started our journey
                  since 2007. We are working for giving the best Product within
                  Different Design to our Buyer. <b>CB FASHION ASIA</b> is the
                  name of quality and lucrative product of buyer which is always
                  ready to accept the challenge for designing any fashion and
                  aiming to attain the top position in the international{" "}
                  <b>Fashion Market</b>.
                </p>
                <p data-aos="fade-right">
                  About 11 years it has been working with a great success and
                  leading now a very experienced and qualified team. We are 100%
                  export & import oriented company and we believe that the truly
                  powerful & enduring alliances can only be guaranteed by
                  delivering total customer satisfaction in the garmenting &
                  related service process.
                </p>
                <p data-aos="fade-left">
                  With the view of creativity and customization we are working
                  with reputed importers in European & American market and we
                  are working for enhancing our business through Global World.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="approved_certificate">
        <Container>
          <Row>
            <div className="col-md-8">
              <div className="certificate_wrapper">
                <Row>
                  <div className="col-md-3">
                    <div data-aos="fade-left" className="certificate">
                      <img
                        className="img-fluid"
                        src={accord}
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div data-aos="fade-left" className="certificate">
                      <img className="img-fluid" src={bsci} alt="" srcSet="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div data-aos="fade-right" className="certificate">
                      <img className="img-fluid" src={sedex} alt="" srcSet="" />
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="flip-left" className="content_wrapper">
                <h2>Our standard</h2>
                <p>
                  We always follow up our standard as per bellow Rules of buyer
                  . Our maximum factory have to follow the rules of BSCI ,SEDEX
                  & ACCORD
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="creative_message_section">
        <Container>
          <div className="creative_message_top">
            <h2 data-aos="fade-up">We do creative & Trendy fashion clothing</h2>
            <h6 data-aos="fade-down">
              Clothing Manufacturer Providing healthy services over years,we
              manufacture & export Baby Clothes, Kids Clothes, Women’s Clothing,
              Men's Clothing, T-Shirt, Polo Shirts, Hoodies, Sweatshirts, Pant,
              Shorts, Skirts, Dresses, Sportswear, School Uniforms, Sports
              Uniforms, Corporate Uniforms & Custom Clothing.
            </h6>
          </div>
          <Row>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>Sampling lead-time in days</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>
                  Production Lead time (Its depends on the styling, quantity)
                </h5>
              </div>
            </div>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>MOQ Pieces(Per Style, Colour, Design)</h5>
              </div>
            </div>
            <div className="col-md-3">
              <div data-aos="zoom-in-down" className="creative_message">
                <h5>Percent Satisfaction</h5>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      {/* Home Page Counter Section */}
      <section className="counter_section">
        <Container>
          <Row>
            <div className="counter_title">
              <h1 className="mb-5">BUSINESS WE DEALT SO FAR</h1>
            </div>
            <div className="counter_content">
              <Row>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && <CountUp end={100} duration={3}></CountUp>}
                    </h1>
                  </ScrollTrigger>
                  <h2>Catagories</h2>
                  <h5>Available</h5>
                </div>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && (
                        <CountUp end={11} duration={2.75}></CountUp>
                      )}
                    </h1>
                  </ScrollTrigger>
                  <h1>Years</h1>
                  <h5>Of Experience</h5>
                </div>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && (
                        <CountUp end={230} duration={2.75}></CountUp>
                      )}
                    </h1>
                  </ScrollTrigger>
                  <h1>Assigned</h1>
                  <h5>Manufacturing Units</h5>
                </div>
                <div data-aos="fade-up" className="col-md-3 col-12">
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <h1>
                      {counterOn && (
                        <CountUp end={17} duration={2.75}></CountUp>
                      )}
                    </h1>
                  </ScrollTrigger>
                  <h1>Associated</h1>
                  <h5>Countries</h5>
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
      {/* Home Page Client Logo Section */}
      <section className="our_client">
        <div className="our_client_title">
          <h3>OUR CLIENTS, BRANDS, CUSTOMERS- AROUND THE WORLD</h3>
        </div>
        <Container>
          <Row>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img
                  style={{ height: "178px" }}
                  src={usPolo}
                  alt="usPolo"
                  srcSet=""
                />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img
                  style={{ height: "180px" }}
                  src={richCotton}
                  alt="richCotton"
                  srcSet=""
                />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={guess} alt="guess" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={emart} alt="emart" srcSet="" />
              </div>
            </div>
          </Row>
          <Row className="mt-4">
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={head} alt="head" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={joyRich} alt="joyRich" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={homePlus} alt="homePlus" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={hardRock} alt="hardRock" srcSet="" />
              </div>
            </div>
          </Row>
          <Row className="mt-4">
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={eddiesBauer} alt="eddiesBauer" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={forever} alt="forever" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img src={skechers} alt="skechers" srcSet="" />
              </div>
            </div>
            <div data-aos="fade-up" className="col-md-3 col-6">
              <div className="img_wrapper">
                <img
                  style={{ height: "178px" }}
                  src={keithHaring}
                  alt="keithHaring"
                  srcSet=""
                />
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}
