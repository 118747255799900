import { Route, Routes } from "react-router-dom";
import "./App.css";
import CompanyProfile from "./components/CompanyProfile";
import Compliance from "./components/Compliance";
import Contact from "./components/Contact";
import KidsProducts from "./components/KidsProducts";
import Footer from "./components/Layouts/Footer/Footer";
import Navigation from "./components/Layouts/Navigation/Navigation";
import Management from "./components/Management";
import MansProducts from "./components/MansProducts";
import Quality from "./components/Quality";
import Services from "./components/Services";
import Vision from "./components/Vision";
import WomenProducts from "./components/WomenProducts";
import HomePage from "./Pages/HomePage";
function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/management" element={<Management />} />
        <Route path="/services" element={<Services />} />
        <Route path="/mens" element={<MansProducts />} />
        <Route path="/womens" element={<WomenProducts />} />
        <Route path="/kids" element={<KidsProducts />} />
        <Route path="/quality" element={<Quality />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
