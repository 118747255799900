import Carousel from "react-bootstrap/Carousel";
import "animate.css";
import "./HeroSlider.css";
function HeroSlider() {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="slider_img_one"></div>
        <Carousel.Caption>
          <div className="slidercontent">
            <h2 className="animate__animated animate__fadeInRightBig">
              Manufacturer & exporter of
            </h2>
            <p className="animate__animated animate__fadeInLeftBig">
              all kinds of apparel & readymade garments goods (knitwear, denim,
              woven items) for Men, Women, Kids.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slider_img_two"></div>
        <Carousel.Caption>
          <div className="slidercontent">
            <h2 className="animate__animated animate__fadeInTopLeft">
              Fashion is an art form & CB is the innovative art-hub.
            </h2>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slider_img_three"></div>
        <Carousel.Caption>
          <div className="slidercontent">
            <h2 className="animate__animated animate__zoomInDown">
              Quality assurance | Lead timing | Fair Trade
            </h2>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroSlider;
