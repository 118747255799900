import React from "react";
import "./Navigation.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        // fixed="top"
        className="shadow-lg "
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            CBFASHIONASIALTD
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              {/* About Dropdown */}
              <NavDropdown
                title="About"
                id="collasible-nav-dropdown"
                menuVariant="light"
              >
                <NavDropdown.Item as={Link} to="/company-profile">
                  Company Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/vision">
                  Vision
                </NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/management">
                  Our Management
                </NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link as={Link} to="/services">
                Our Services
              </Nav.Link>
              {/* Products Dropdown */}
              <NavDropdown
                title="Products Gallery"
                id="collasible-nav-dropdown"
                menuVariant="light"
              >
                <NavDropdown.Item as={Link} to="/mens">
                  Mens
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/womens">
                  Womens
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/kids">
                  Kids
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/quality">
                Quality
              </Nav.Link>
              <Nav.Link as={Link} to="/compliance">
                Compliance
              </Nav.Link>
              <Nav.Link as={Link} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
